:root{
    --color-1: #f7bfb1;
    --color-2: #a5b4cf;
    --color-3: #5b7fa1;
    --color-4: #fff;
    --color-5: #5b7fa1;
    --color-6: #a5b4cf;
    --color-7: #3a425d;


    --color-8: #fac87c;
    --color-10: var(--color-background);
    --background: #edeef0;
    
    --color-14: #fff;
    --white-color: #fff;
}