@import './vars';


@media screen and (max-width: 1440px) {
}


@media screen and (max-width: 1024px) {
    .sprite-blueSite{
        padding: 40px 15px;
    }
    .sprite-blueWrapper{
        width: 100%;
    }
    .sprite-blueSidebar{
        display: none;
        flex-direction: column;
        width: 290px;
        padding: 15px;
        position: absolute;
        background: var(--color-2);
        z-index: 2000;
        right: 0;
        top: 0;
        padding-top: 100px;
    }
    .header-st_header_inner{
        display: grid;
    }
    .header-st_header_menu{
        grid-column-start: 1;
        grid-column-end: 3;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    } 
    .header-st_mobile__button{
        display: flex;
        justify-self: end;
    }
    .import_ser-track-info, .import_ser-track-central{
        height: 70px;
    }
    .import_ser-mute-box__muteUnmute{
        display: none;
    }
    .header-st_header_menu a{
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .import_ser-mute-box{
        display: none;
    }
    .import_ser-letter__link{
        background: var(--color-3);
    }
}

@media screen and (max-width: 992px) {
    .import_ser-melodious__list a{
        font-size: 24px;
    }
    .import_ser-track-info{
        margin-right: 60px;
    }
    }
@media screen and (max-width: 920px) {
}

@media screen and (max-width: 768px) {
    .sprite-bluePopular_title{
        font-size: 25px;
    }
    .import_ser-player-title_heading{
        margin-right: 0;
    }
    .import_ser-track__leftWrapper{
        justify-self: end;
    }
    .track{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .import_ser-player-title{
        justify-content: center;
        margin-top: 15px;
        order: 2;
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .import_ser-track-central{
        margin-right: 0;
    }
    .import_ser-results-info__card{
        padding: 15px 10px;
    }
    .import_ser-melodious__list{
        flex-direction: row;
    }
    .import_ser-melodious__list a{
        margin-right: 5px;
        margin-left: 5px;
    }
    .import_ser-boxline__bottom{
        text-align: center;
    }
    .import_ser-mute-box{
        margin-right: 30px;
    }
    .import_ser-about{
        flex-direction: column;
    }
    .import_ser-logo-link{
        display: none;
    }
    .import_ser-wrapper{
        margin-left: 0;
    }

    .import_ser-mobile-nav__button{
        width: min-content;
        justify-self: end;
    }
    .import_ser-song-aplhabet{
        width: auto;
    }
    .import_ser-popular_title{
        font-size: 25px;
    }
    .import_ser-popular_logo{
        width: 40px;
        height: 40px;
    }
    .import_ser-copyright_box-mail{
        font-size: 24px;
    }
    .import_ser-sidebar{
        display: none;
    }
    .import_ser-wrapper{
        width: 100%;
    }
    .import_ser-melodious__list a::before{
        display: none;
    }
    .import_ser-footer_copy{
        width: 100%;
    }
}

@media screen and (max-width: 624px) {
    .import_ser-copyright_box-mail a{
        margin-left: 0;
    }
    .track{
        flex-direction: column;
    }
    .import_ser-copyright_box{
        margin-bottom: 230px;
    }
    .social-box{
        right: 30px;
    }

    .import_ser-about{
        margin-bottom: 190px;
    }
    .import_ser-track-central{
        margin-right: 0;
    }
    .import_ser-track-info{
        margin-bottom: 10px;
    }
    .import_ser-mute-box {
        width: auto;
        justify-self: center;
        grid-column-start: 2;
        margin-right: 0;
    }
    .import_ser-time{
        margin-right: 15px;
    }
    .import_ser-zero-time{
        margin-left: 0;
        font-size: 16px;
    }
    .import_ser-time_space{
        font-size: 16px;
    }
    .import_ser-timeBox{
        margin-right: 0;
        font-size: 16px;
    }
    .import_ser-copyright_box-mail{
        flex-direction: column;
    }
}

@media screen and (max-width: 670px) {
    .import_ser-melodious__list a{
        justify-content: center;
    }
}

@media screen and (max-width: 577px) {

    .import_ser-popular_logo{
        display: none;
    }
    .import_ser-popular_title{
        text-align: center;
    }
    .import_ser-sotial-network-vk_btn{
        width: 100%;
        margin-bottom: 20px;
    }
    .import_ser-sotial-network-vk_link{
        width: 100%;
    }
    .import_ser-sotial-network-vk_input{
        flex-direction: column;
    }


    .import_ser-download_vk{
        width: 100%;
    }
    .import_ser-vk{
        width: 100%;
        min-width: auto;
    }
    .import_ser-social-box{
        padding-bottom: 0;
    }
    .import_ser-player-title_heading{
        margin-right: 0;
    }
    .import_ser-results-info__card_download{
        width: 40px;
        height: 40px;
    }
}    


@media screen and (max-width: 510px) {
    .import_ser-track-info{
        margin-right: 0;
    }
    .import_ser-player-title{
        margin-top: 0;
    }
    .import_ser-copyright_box-mail{
        font-size: 22px;
        margin-top: 10px;
    }
    .import_ser-player-title_heading{
        text-align: center;
        margin-top: 10px;
    }
    .progress-bar__container{
        flex-direction: column;
    }
    .sprite-blueSocial_vk{
        display: none;
    }
    .res-card_st-bf_results-info__card-subtext, .import_ser-subinfo__time{
        display: none;
    }
    .import_ser-player-title{
        text-align: center;
        margin-left: 0;
        justify-content: center;
        margin-bottom: 0;
    }
    .limport_ser-description{
        margin-bottom: 20px;
    }
    .track{
        display: flex;
        flex-direction: column;
    }
    .import_ser-mute-box{
        display: none;
    }
    .import_ser-track-info{
        flex-direction: column;
        align-items: center;
    }
    .import_ser-track-central{
        margin-bottom: 10px;
    }

}

@media screen and (max-width: 470px) {
    .import_ser-description_content{
        display: none;
    }
    .sprite-bluePopular__box{
        display: none;
    }
    .import_ser-player-title_heading{
        font-size: 18px;
    }
}

@media screen and (max-width: 425px) {
    .import_ser-description_title{
        display: none;
    }
    .music-artist__card_artist, .music-artist__card_tracklink{
        max-height: 17px;
    }
    .header-st_header_menu{
        flex-direction: column;
        align-items: center;
    }
    .header-st_header_menu a{
        font-size: 24px;
    }
    .music-artist__card_download svg{
        width: 40px;
        height: 25px;
    }
    .music-artist__card_download{
        width: 40px;
        height: 20px;
    }
    .title{
        font-size: 14px;
    }
    .music-artist__card{
        height: 70px;
        padding: 10px 5px 5px 5px;
    }
    .music-artist__card_time{
        display: none;
    }
    .music-artist__card .start{
        transform: scale(0.6);
    }
    .music-artist__card_download{
        position: static;
    }
    .music-artist__card-subtext{
        display: none;
    }
    .res-card_st-bf_results-info__card{
        padding: 15px 10px;
    }
    .import_ser-popular__box{
        justify-content: center;
    }
    .import_ser-melodious__heading{
        text-align: center;
    }
    .import_ser-description_title{
        text-align: center;
    }
    .import_ser-subinfo__time{
        display: none;
    }
    .import_ser-results-info__card_time{
        margin-right: 0;
    }
    .import_ser-results-info__card-subtext{
        margin-right: 10px;
        margin-left: 15px;
    }
    .import_ser-results-info__card_artist, .import_ser-results-info__card_space, .import_ser-results-info__card_tracklink{
        font-size: 14px;
    }
    .import_ser-results-info__card_like{
        display: none;
    }
    
    .import_ser-results-info__card_download svg{
        width: 30px;
        height: 30px;
    }
    .import_ser-results-info__card.pausing a.import_ser-results-info__card_link::before {
        content: url(../images/musicTheme-play.svg);
        padding-left: 12px;
        padding-right: 8px;
        height: 22px;
    }
    .import_ser-results-info__card_link::before{
        width: 20px;
        height: 20px;
        padding: 11px;
    }
    .import_ser-results-info__card .start{
        width: 40px;
        height: 40px;
    }
    .import_ser-vk::before{
        min-width: calc(300px + 6px);
    }
    .import_ser-melodious__list{
        display: flex;
        flex-direction: column;
    }
    .import_ser-melodious__list a{
        font-size: 30px;
    }
    .header-st_header_logo-link{
        font-size: 30px;
    }
}

@media screen and (max-width: 375px) {
} 